<template>
  <div class="dt-table">
    <table class="dt-table__table">
      <thead>
        <tr :style="'background-color:'+bgcolor+';color:'+color">
          <th
            v-for="hf in heading"
            @click="changeOrder(hf.code)"
            :style="'border-color:'+bgcolor"
            :key="'dt-'+hf.code+'-'+hf.text"
          >
            <span class="dt-table__table__sort" :class="order == 'asc' ? 'dt-table__table__sort__asc' : 'dt-table__table__sort__desc'" v-if="orderBy == hf.code"></span>
            {{ hf.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="d,i in data" :key="'dt-'+i">
          <tr v-if="i < 10">
            <td v-for="hf in heading" :key="'dt-'+i+'-'+hf.code+'-'+hf.text">
              <slot :name="hf.code" :row="d">{{ d[hf.code] }}</slot>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div class="dt-table__pagination">
      <button :style="'background-color:'+bgcolor+';color:'+color" :disabled="currentPage == 1 ? true : false" type="button" class="dt-table__pagination__prev" @click="() => changePage(currentPage-1)"></button>

      <template v-for="index in numberPages" :key="'pag-'+index">
        <button :style="'background-color:'+bgcolor+';color:'+color" :disabled="currentPage == index ? true : false" type="button" class="" @click="() => changePage(index)">{{ index }}</button>
      </template>

      <button :style="'background-color:'+bgcolor+';color:'+color" :disabled="currentPage == numberPages ? true : false" type="button" class="dt-table__pagination__next" @click="() => changePage(currentPage+1)"></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Table',
  props: ['heading', 'data', 'total', 'perPage', 'currentPage', 'bgcolor', 'color', 'order', 'orderBy'],
  emits: ['changePage'],
  computed: {
    numberPages: function(){
      return Math.ceil(this.total / (this.perPage ? this.perPage : 1))
    },
  },
  methods:{
    changePage: function(page) {
      this.$emit('changePage', { page: page, orderBy: this.orderBy, order: this.order })
    },
    changeOrder: function(orderBy){
      let order = this.order
      if(this.orderBy == orderBy) {
        order = order == 'asc' ? 'desc' : 'asc'
      }
      this.$emit('changePage', { page: this.currentPage, orderBy: orderBy, order: order })
    }
  },
  created: function(){
  }
}
</script>

<style scoped>
.dt-table {
  overflow-x: scroll;
}

.dt-table__table {
  width: 100%;
}

.dt-table__table,
.dt-table__table th,
.dt-table__table td {
  border: 1px solid #ddd;
  border-collapse: collapse;
  text-align: left;
}

.dt-table__table th,
.dt-table__table td {
  padding: 7px;
  position:relative;
}

.dt-table__table th {
  cursor: pointer;
  position: relative;
}

.dt-table__table__sort {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.dt-table__table__sort__asc::before {
  content: "\2191";
  display: block;
}

.dt-table__table__sort__desc::before {
  content: "\2193";
  display: block;
}

.dt-table__pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dt-table__pagination button {
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  outline: none;
  position: relative;
  margin: 0 2px;
  cursor: pointer;
}

.dt-table__pagination button:disabled {
  cursor: not-allowed;
  opacity: .4;
}

.dt-table__pagination__prev::before {
  content: '';
  width: 10px;
  height: 10px;
  display: block;
  border-left: 2px solid #fff;
  border-top: 2px solid #fff;
  transform: rotate(-45deg);
  position: absolute;
  top: 9px;
  left: 11px;
}

.dt-table__pagination__next::before {
  content: '';
  width: 10px;
  height: 10px;
  display: block;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
  position: absolute;
  top: 9px;
  right: 11px;
}
</style>