<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-back-button color="primary"></ion-back-button>
        </ion-buttons>
        <ion-title>Companies</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar> 
        </ion-toolbar>
      </ion-header>
      <div id="container"> 
        <ion-grid class="ricerca">
          <ion-row>
            <ion-col id="rcconsulente">
              <ion-label id="lconsulente">
                Nome:
              </ion-label>
              <ion-input id="ricconsulente"  v-model="search.name"></ion-input>  
            </ion-col>
            <ion-col id="rctipologia">
              <ion-label id="ltipologia">
                Risorsa:
              </ion-label>
              <ion-input id="ricconsulente"  v-model="search.consultant"></ion-input>  
            </ion-col>
            <ion-col id="rcinizio">
              <ion-label id="ldatainizio">
                Luogo:
              </ion-label>
              <ion-input id="ricconsulente"  v-model="search.place"></ion-input>  
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-button class="btncerca" @click="getCompanies()">
                RICERCA
            </ion-button>
            <ion-button class="btnfiltro" color='warning' @click="svuotaFiltro()">
              X
            </ion-button>
          </ion-row>
        </ion-grid>

        <ion-button id="nuovobtn" @click="$router.push('/folder/company')">NUOVO</ion-button>
        <dtable          
          color="#fff"
          bgcolor="rgb(56,128,255)"
          :heading="CompaniesHeading"
          :data="companies"
          :total="table.total"
          :perPage="table.perPage"
          :currentPage="table.currentPage"
          :orderBy="table.orderBy"
          :order="table.order"
          @changePage="getCompanies"
        >
          <template v-slot:btns="slotProps">
            <ion-button color="danger" @click="alertDelete(slotProps.row.id)">
              <ion-icon :icon="trashOutline">
            </ion-icon></ion-button> 
            <ion-button @click="$router.push('/folder/company-modifica/'+slotProps.row.id);">
              <ion-icon :icon="pencilOutline"></ion-icon>
            </ion-button>
          </template>
        </dtable>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonLabel,
    IonMenuButton, 
    IonPage,
    IonRow, 
    IonTitle, 
    IonToolbar,
    alertController
  } from '@ionic/vue';
  import { pencilOutline,trashOutline} from 'ionicons/icons';
  import Dtable from './partials/Dtable';

  export default {
    name: 'Folder',
    components: {
      IonBackButton,
      IonButton,
      IonButtons,
      IonCol,
      IonContent,
      IonGrid,
      IonHeader,
      IonIcon,
      IonInput,
      IonLabel,
      IonMenuButton, 
      IonPage,
      IonRow, 
      IonTitle, 
      IonToolbar,
      Dtable,
    },
    data: function(){
      return{
        companies:[],
        pencilOutline:pencilOutline,
        trashOutline:trashOutline,
        CompaniesHeading: [
          {
            text: 'Nome',
            code: 'name'
          },
          {
            text: 'Risorsa',
            code: 'consultant'
          },
          {
            text: 'Luogo',
            code: 'place'
          },
          {
            text: '',
            code: 'btns'
          }            
        ],
        table: {
          currentPage: 1,
          total: '',
          perPage: 0,
          orderBy: 'name',
          order: 'asc',
        },
        search:{
          name:'',
          consultant:'',
          place:'',
        }
      }
    },
    methods:{
      submit(ev){
        ev.preventDefault();     
      },
      getCompanies(obj=false){
        if(obj) {
          this.table.currentPage = obj.page
          this.table.orderBy = obj.orderBy
          this.table.order = obj.order
        }
        this.axios.post(this.apiUrl + 'get-companies', {page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order, search:this.search}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
              this.companies=response.data.companies.data
              this.table.total = response.data.companies.total
              this.table.perPage = response.data.companies.per_page        
          }
        })
      },
      deleteCompany(id){
        this.axios.post(this.apiUrl + 'delete-company', {id:id}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.getCompanies();              
          }         
        });
      },
      removeCompany: function(company) {
        var companies = this;
        this.http.delete('/folder/companies', company).then(
          function () { 
              companies.list.$remove(company);
            },
            function () {
              alert('Something went wrong.');
            }
          );
        },
        async alertDelete(id) {
          const alert = await alertController
            .create({
              header: 'Attenzione:',
              message: 'Eliminare la company?',
              buttons: [
                {
                  text:'Annulla', 
                  role: 'cancel',
                },
                {
                  text:'Conferma',
                  handler: () => {
                    this.deleteCompany(id);
                  }
                }
              ],
            });
          return alert.present();
        },
        svuotaFiltro(){
        this.search.name='',
        this.search.consultant='',
        this.search.place='',
        
        this.getCompanies();
      }
      },
      ionViewWillEnter: function(){
        this.getCompanies();
      }
    }
</script>
<style scoped>
  #container{
    width:90%;
    height:100%;
    margin:5%;
    margin-bottom:5%;
  }
  ion-button{
    width:40%;
  }
  #bottoni{
    width:20px;
  }
  
  .header{
    background: darkblue;
    font-weight: bold;
    color:white;
    text-align:center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom:5px solid white;
    }
  .headercol{
      border-right:2px inset rgba(33, 209, 200, 0.479);
    }

  .content{
      border-right:2px inset rgba(33, 209, 200, 0.158);
      border-bottom:2px inset rgba(33, 209, 200, 0.158);
    }

  #nuovobtn{
    width:20%;
    margin-top:-5%;
  }

  .ricerca{
    margin:10px;
    border:1px solid grey;
    border-radius:5px;
    margin-bottom:5%;
  }
  #ricdatainizio,#ricdatafine,#ricconsulente,#ricconsulente,#rictipologia,#ricorainizio,#ricorafine,#ricstatus{
    border:1px solid rgb(184, 184, 184);
    border-radius:5px;
  }
  #lstatus,#lconsulente,#ltipologia,#ldatainizio,#ldatafine,#lorainizio,#lorafine{
    border:1px solid rgb(56,128,255);
    border-radius:5px;
    background-color:rgb(56,128,255);
    font-weight:bold;
    color:#fff;
  }
  .btncerca{
    width:92%;
  }
  .btnfiltro{
    width:5%;
  }
  #buttons{
    display:inline;
  }
  @media only screen and (max-width: 600px) {
    ion-button{
      width:100%;
    }
  }
</style>